import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ title, description }) => (
  <header className="bg-white shadow">
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 text-center">
      <h1 className="text-4xl font-bold uppercase leading-tight">
        <Link to="/">{title}</Link>
      </h1>
      <div className="text-sm font-mono italic text-gray-600">
        {description}
      </div>
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

Header.defaultProps = {
  title: ``,
  description: ``
}

export default Header
