import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Post = ({
  className,
  imageFluid,
  imageClassName,
  imageAlt,
  date,
  title,
  html
}) => {
  return (
    <div className={`bg-gray-100 border-8 border-gray-900 post ${className}`}>
      <Img
        fluid={imageFluid}
        className={`w-full object-cover image-post ${imageClassName}`}
        alt={imageAlt}
      />
      <div className="p-6">
        <small className="text-gray-600 text-sm inline-block mb-2">
          {date}
        </small>
        <header>
          <h2 className="text-2xl font-bold">{title}</h2>
        </header>
        <section>
          <div
            className="text-gray-700 mt-2"
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
        </section>
      </div>
    </div>
  )
}

Post.propTypes = {
  className: PropTypes.string,
  imageFluid: PropTypes.object,
  imageClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string
}

export default Post
