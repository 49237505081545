/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteMetadataConfigQuery {
      siteMetadata {
        title
        description
        backgroundColor
        hasBackgroundPattern
        footer
      }
    }
  `)
  const {
    title,
    description,
    backgroundColor,
    hasBackgroundPattern,
    footer
  } = data.siteMetadata

  return (
    <div
      style={{
        backgroundColor: backgroundColor || "rgb(219, 212, 198)",
        backgroundImage:
          hasBackgroundPattern &&
          `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.38' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"`
      }}
      className="text-gray-900"
    >
      <Header title={title} description={description} />
      <div>
        <main>{children}</main>
        <footer
          className="footer border-t mt-8 px-6 py-4 text-gray-600 bg-gray-100"
          dangerouslySetInnerHTML={{
            __html: footer
          }}
        ></footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
